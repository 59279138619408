// Comment unused dependencies

import * as FormValidation from  '@theme/plugins/@form-validation/esm/bundle/full.js';
import '@theme/plugins/@form-validation/esm/bundle/popular.js';
import { Bootstrap5 } from '@theme/plugins/@form-validation/esm/plugin-bootstrap5/index.js';

import '@node_modules/bootstrap-daterangepicker/daterangepicker.js';
import '@node_modules/bootstrap-maxlength/src/bootstrap-maxlength.js';
import "@node_modules/bootstrap-multiselectsplitter/bootstrap-multiselectsplitter.js";
import Select2 from '@node_modules/select2/dist/js/select2.full.js';
import ClipboardJS from "@node_modules/clipboard/dist/clipboard.min.js";
import "@node_modules/@yaireo/tagify/dist/tagify.polyfills.min.js";
import Tagify from "@node_modules/@yaireo/tagify/dist/tagify.min.js";
import swal from "@node_modules/sweetalert2/dist/sweetalert2.min.js";

import * as tempusDominus from '@node_modules/@eonasdan/tempus-dominus/dist/js/tempus-dominus.esm.min.js';

import '@node_modules/@eonasdan/tempus-dominus/dist/locales/de.js';
import '@node_modules/@eonasdan/tempus-dominus/dist/plugins/customDateFormat.js';

import '@node_modules/flatpickr/dist/flatpickr.js';
import '@node_modules/flatpickr/dist/l10n/ar.js';
import "@theme/plugins/toastr/build/toastr.min.js"

import '@node_modules/inputmask/dist/inputmask.js';
import '@node_modules/inputmask/dist/bindings/inputmask.binding.js';


window.FormValidation = FormValidation;
window.FormValidation.plugins.Bootstrap5 = Bootstrap5;
window.tempusDominus = tempusDominus;
window.ClipboardJS = ClipboardJS;
window.Tagify = Tagify;
window.Swal = window.swal = swal;


Select2();


import "@theme/plugins/keenicons/solid/fonts/keenicons-solid.woff";
import "@theme/plugins/keenicons/outline/fonts/keenicons-outline.woff";
import "@theme/plugins/keenicons/duotone/fonts/keenicons-duotone.woff";
import "@node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2";
import "@node_modules/line-awesome/dist/line-awesome/fonts/la-regular-400.woff2";
import "@node_modules/line-awesome/dist/line-awesome/fonts/la-brands-400.woff2";
import "@node_modules/line-awesome/dist/line-awesome/fonts/la-solid-900.woff2";
import "@node_modules/@fortawesome/fontawesome-free/webfonts/fa-brands-400.woff2";
import "@node_modules/@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff2";
import "@node_modules/@fortawesome/fontawesome-free/webfonts/fa-v4compatibility.woff2";
import "@node_modules/@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2";

import "@node_modules/fslightbox/index.js";
// import "@node_module/typed.js/dist/typed.umd.js";
